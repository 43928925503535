/**
 * Created by sxw at 2024/06/25
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/equ/tmonitoring/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/equ/tmonitoring/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/equ/tmonitoring/save`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl("/equ/tmonitoring/delete"),
		method: "post",
		data: http.adornData(data, false),
	})
}

export function screenshot(data) {
	return http({
		url: http.adornUrl(`/equ/tmonitoring/screenshot`),
		method: "post",
		data: http.adornData(data),
	})
}

export function openEqu(data) {
	return http({
		url: http.adornUrl(`/equ/tmonitoring/equController`),
		method: "post",
		data: http.adornData(data),
	})
}

export function isNormallyOpen(data) {
	return http({
		url: http.adornUrl(`/equ/tmonitoring/isNormallyOpen`),
		method: "post",
		data: http.adornData(data),
	})
}

export function infoBoard(id) {
	return http({
		url: http.adornUrl('/equ/tBoardConfig/getByequId/' + id),
		method: "get",
	})
}

export function saveOrUpdateBoard(data) {
	return http({
		url: http.adornUrl(`/equ/tBoardConfig/saveAndUpd`),
		method: "post",
		data: http.adornData(data),
	})
}

export function configList(id) {
	return http({
		url: http.adornUrl('/park/displayconfig/getList' + (id ? '/' + id : '')),
		method: "get",
	})
}

export function templateList() {
	return http({
		url: http.adornUrl('/park/displaytemplate/getList'),
		method: "get",
	})
}

export function codeConfigList() {
	return http({
		url: http.adornUrl('/park/codeConfig/getList'),
		method: "get",
	})
}

export function codeConfigSave(data) {
	return http({
		url: http.adornUrl(`/equ/tBoardCodeInout/save`),
		method: "post",
		data: http.adornData(data),
	})
}

export function boardCodeInout(data) {
	return http({
		url: http.adornUrl(`/equ/tBoardCodeInout/info`),
		method: "post",
		data: http.adornData(data),
	})
}

export function setVolume(data) {
	return http({
		url: http.adornUrl(`/equ/tBoardConfig/setVolume`),
		method: "post",
		data: http.adornData(data),
	})
}

export function getEquLog(data) {
	delete data.total
	return http({
		url: http.adornUrl("/equ/tmonitoring/getEquLog"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function getEquErrorLog(data) {
	delete data.total
	return http({
		url: http.adornUrl("/equ/getErrorList"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function pullError(data) {
	return http({
		url: http.adornUrl("/equ/pullError"),
		method: "post",
		data: http.adornData(data),
	})
}

export function importEquError(data) {
	delete data.total
	delete data.page
	delete data.limit
	return http({
		url: http.adornUrl("/equ/importEquError"),
		method: "post",
		data: http.adornData(data),
	})
}

export function lcdscreenList(data) {
	delete data.total
	return http({
		url: http.adornUrl("/equ/lcdscreen/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function lcdscreenInfo(id) {
	return http({
		url: http.adornUrl('/equ/lcdscreen/info/' + id),
		method: "get",
	})
}

export function lcdscreenSaveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/equ/lcdscreen/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function lcdscreenDel(id) {
	return http({
		url: http.adornUrl("/equ/lcdscreen/delete/"+id),
		method: "get",
	})
}

export function boardCarTypeList(data) {
	return http({
		url: http.adornUrl(`/equ/boardLcdReplaceType/list`),
		method: "post",
		data: http.adornData(data),
	})
}

export function saveOrUpdateBoardCarType(data) {
	return http({
		url: http.adornUrl(`/equ/boardLcdReplaceType/saveAndUpd`),
		method: "post",
		data: http.adornData(data),
	})
}


export function boardCarTypeDel(id) {
	return http({
		url: http.adornUrl("/equ/boardLcdReplaceType/del/"+id),
		method: "get",
	})
}

export function lcdEmqx(data) {
	return http({
		url: http.adornUrl(`/equ/lcdscreen/lcdEmqx`),
		method: "post",
		data: http.adornData(data),
	})
}